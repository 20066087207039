<template>
    <el-dialog
       :visible="dialogShow"
       title="确认信息"
       width="30%"
       :before-close="handleClose"
       append-to-body
    >
      <div class="content">
        是否要开体验账户
      </div>
      <div slot="footer" class="dialog-footer">
          <el-button
            @click="cancleHandle"
          >取 消</el-button>
          <el-button
            type="primary"
            :loading="btnLoading"
            @click="confirmHandle"
          >确 定</el-button>
      </div>
    </el-dialog>
</template>

<script>
  import { openTrialAccount } from '@/api/bussetup/tMt4Group'
  export default {
    name: 'openExperienceDialog',
    props: {
      dialogShow: {
        type: Boolean,
        default: false,
      },
      custId:{
        type: Number,
        default: 0,
      }
    },
    data() {
      return {
        btnLoading: false,
      }
    },
    methods: {
      close(){
        this.$emit('close');
      },
      handleClose() {
        this.close();
      },
      cancleHandle(){
        this.close();
      },
      confirmHandle(){
        this.btnLoading = true;
        openTrialAccount({
          custId: this.custId
        }).then((res) => {
          this.btnLoading = false;
          this.$emit('close');
          this.$emit('success-done');
          this.$notify({
            title: '操作成功',
            type: 'success',
            duration: 1000
          })
        }).catch(() => {
          this.btnLoading = false;
        })
      }
    }
  }
</script>

<style scoped>

</style>
