import { sendPhoneVerCode, authPhoneVerCode } from "@/api/system/user";
import { initData } from '@/api/data';
export default {
  data() {
    return {
      // viewDetail的操作
      dialogFormVisible: false,
      smsCode: "",
      show: true,
      count: "",
      timer: null,
      viewData:{},
      // 审核是不显示子代理链接,查看时显示全部子代理链接
      isShowSubLink: true,
      currentRowData:{
        id: ''
      },
      isCheck: false,
      // viewDetail的操作
    }
  },
  methods: {
    // viewDetail的操作
    to(isShowSubLink,row) {
      this.currentRowData = row;
      this.isShowSubLink = isShowSubLink == 1 ? true : false;
      const _this = this.$refs.detail;
      var op = "";
      if (this.isCheck) {
        op = "审核";
        if (this.$store.getters.mtGroups == null) {
          this.$store.dispatch("GetMtGroups");
        }
      } else {
        op = "查看";
      }
      _this.title = op;
      const url = 'crm/tCustInfo'
      const sort = 'id,desc'
      const params = { id:this.currentRowData.id,page: 0, size: 10, sort: sort ,flag:'false'}
      initData(url, params).then(res => {
        this.viewData = res.content[0];
        _this.dialog = true;
      }).catch(err => {
        this.loading = false
      })
    },
    severLookHandle(row){
      this.currentRowData = row;
      this.dialogFormVisible = true;
    },
    getCode() {
      if(!this.smsCode){
        this.$message({
          type: 'warning',
          message: '请输入手机号码'
        });
        return;
      }
      let params = { id: this.currentRowData.id };
      sendPhoneVerCode(params)
        .then(res => {
          this.$notify({
            title: res.message,
            type: "success",
            duration: 2500
          });
        })
        .catch(err => {});
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    submitCode() {
      this.dialogFormVisible = false;
      let params = {
        id: this.currentRowData.id,
        verCode: this.smsCode
      };
      authPhoneVerCode(params)
        .then(res => {
          // if(res.status==200){
          this.to();
          // }else{
          //   this.$notify({
          //   title: res.message,
          //   type: "error",
          //   duration: 2500
          // });
          // }
        })
        .catch(err => {});
    }
    // viewDetail的操作
  }
}
