<template>
  <el-dialog
      class="ib-info-dialog"
      :append-to-body="true" :visible.sync="dialog" :title="isEdit ? '编辑' : '新增'" width="1000px">
<!--    代理商没有增加的-->
    <el-form
        ref="form"
        :model="form"
        size="small"
        label-width="100px"
    >
       <el-tabs
           v-model="activeName"
           @tab-click="handleClick"
       >
        <el-tab-pane label="基本资料" name="first">
          <el-row v-if="isIb">
            <el-col :span="8">
              <el-form-item label="代理号">
                <el-input v-model="form.id" style="width: 200px;" :disabled="isEdit"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="代理名称">
                <el-input v-model="form.firstName" style="width: 100px;"/>
                <el-input v-model="form.lastName" style="width: 100px;"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                  class="level-lock-item"
                  label="代理级别：">
                <el-input
                    style="width: 70px;margin-right: 5px"
                    v-model="levelName"
                    disabled
                ></el-input>
                <el-radio v-model="lockLevel" :label="0">锁定</el-radio>
                <el-radio v-model="lockLevel" :label="1">不锁定</el-radio>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="form.custLvl=='4'" :disabled="isEdit">
              <el-form-item label="特殊级别描述">
                <el-input v-model="form.custLvlComment" style="width: 200px;"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-else>
            <el-col :span="8">
              <el-form-item label="ID">
                <el-input v-model="form.id" style="width: 200px;" :disabled="isEdit"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="客户名称">
                <el-input v-model="form.firstName" style="width: 100px;"/>
                <el-input v-model="form.lastName" style="width: 100px;"/>
              </el-form-item>
            </el-col>
            <el-col
                :span="8"
            >
              <el-form-item
                  label="客户级别：">
               <el-select
                   v-model="form.linkId"
                   placeholder="客户级别">
                  <el-option
                    v-for="(item,index) in custLevelList"
                    :key="item.id"
                    :label="item.levelName"
                    :value="item.id">
                  </el-option>
               </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="form.custLvl=='4'" :disabled="isEdit">
              <el-form-item label="特殊级别描述">
                <el-input v-model="form.custLvlComment" style="width: 200px;"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="isIb">
            <el-col :span="8">
              <el-form-item label="代理类型">
                <el-select
                    v-model="form.custType"
                    placeholder="代理类型"
                    style="width: 200px;"
                >
                  <el-option
                    v-for="item in ibTypeOptions"
                    :key="item.key"
                    :label="item.display_name"
                    :value="item.key">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" >
               <el-form-item
                   label="代理层级"
               >
                 <el-select v-model="form.ibRank" placeholder="代理层级" :disabled="isEdit">
                  <el-option
                    v-for="item in ibRankOptions"
                    :key="item.key"
                    :label="item.display_name"
                    :value="item.key">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" >
               <el-form-item label="代理区域">
                <el-input v-model="form.developArea" style="width: 200px;" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col
                v-if="hierarchyDisplay"
                :span="8">
              <el-form-item label="代理层级数">
<!--                :disabled="form.ibRank == 2" -->
                <el-input
                    v-model="form.hierarchy"
                    style="width: 200px;"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="电话">
                <el-input v-model="form.phone" style="width: 200px;"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="邮箱">
                <el-input v-model="form.email" style="width: 200px;" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="出生日期">
                <el-input v-model="form.birthday" style="width: 200px;" :disabled="isEdit"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="交易积分">
                <el-radio v-model="form.rewardPointFlag" :label="1">统计</el-radio>
                <el-radio v-model="form.rewardPointFlag" :label="0">不统计</el-radio>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="开新账户">
                <el-radio v-model="form.openAccountFlag" :label="1">允许</el-radio>
                <el-radio v-model="form.openAccountFlag" :label="0">不允许</el-radio>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="修改杠杆">
                <el-radio v-model="form.openLeverage" :label="1">允许</el-radio>
                <el-radio v-model="form.openLeverage" :label="0">不允许</el-radio>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col
              :span="8"
            >
              <el-form-item
                  label="少数民族风控"
              >
                <el-radio
                    v-model="form.riskControlFlag"
                    :label="1"
                >限制</el-radio>
                <el-radio
                    v-model="form.riskControlFlag"
                    :label="0"
                >不限制</el-radio>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row v-if="isIb">
            <el-form-item label="子代理链接">
              <el-input v-model="form.linkForChild" style="width: 800px;" :disabled="isEdit"/>
            </el-form-item>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="客户注册链接">
                <el-input v-model="form.linkForCust" style="width: 800px;" :disabled="isEdit"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="家庭地址">
               <span>{{form.homeProvince }}-{{form.homeCity }}-{{form.homeStreet }}-{{form.homeAddress }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col >
               <el-form-item label="工作地址">
                <span>{{form.workProvince }}-{{form.workCity }}-{{form.workStreet }}-{{form.workAddress }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="财务资料" name="second">
           <el-row>
            <el-col :span="12">
              <el-form-item label="持卡人姓名">
                <el-input disabled v-model="form.userName" style="width: 370px;"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="证件号码">
                <el-input disabled v-model="form.certId" style="width: 370px;"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="银行卡号">
                <el-input disabled v-model="form.bankAccount" style="width: 370px;"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开户银行">
                <el-input disabled v-model="form.bankName" style="width: 370px;"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
             <el-col :span="12">
              <el-form-item label="swiftCode">
                <el-input disabled v-model="form.swiftCode" style="width: 150px;"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开户网点">
                <el-input
                  disabled
                  :value="form.bankOutletProvince + form.bankOutletCity + form.bankOutlet"
                  style="width: 300px;"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="30">
            <el-col :span="12">
              <el-form-item label="OTC365地址">
                <el-input disabled v-model="form.otcAdress"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="USDT地址:">
                <el-input disabled v-model="form.usdtAdress"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
             <el-col :span="8">
              <span class="label">身份证照片</span>
              <div>
                <img
                    v-if="form.certValidatedImgFront"
                    style="width: 170px;height: 120px"
                     :src="form.certValidatedImgFront"
                     class="zoom-in-img"
                     @click="previewHandle(form.certValidatedImgFront)"
                />
                <img
                    v-if="form.certValidatedImgBack"
                  style="width: 170px;height: 120px"
                  :src="form.certValidatedImgBack"
                  class="zoom-in-img"
                  @click="previewHandle(form.certValidatedImgBack)"
                />
              </div>
            </el-col>
            <el-col :span="8">
              <span class="label">银行卡照片</span>
              <div>
                <img
                    v-if="form.bankFrontImg"
                  style="width: 170px;height: 120px"
                  :src="form.bankFrontImg"
                  class="zoom-in-img"
                  @click="previewHandle(form.bankFrontImg)"
                />
                <img
                    v-if="form.bankBackImg"
                  style="width: 170px;height: 120px"
                  :src="form.bankBackImg"
                  class="zoom-in-img"
                  @click="previewHandle(form.bankBackImg)"
                />
              </div>
            </el-col>
             <el-col :span="8">
              <span class="label">地址证明</span>
              <div>
                 <img
                   style="width: 170px;height: 120px"
                   :src="form.addressImg"
                   class="zoom-in-img"
                   @click="previewHandle(form.addressImg)"
                 />
              </div>
               <el-row :gutter="20">
                 <el-col :span="12">
                   <p>OTC365二维码</p>
                   <img
                     v-if="form.otctQrPic"
                     class="money-img-el zoom-in-img"
                     :src="form.otctQrPic"
                     alt=""
                     @click="previewHandle(form.otctQrPic)"
                   >
                 </el-col>
                 <el-col :span="12">
                   <p>USDT二维码</p>
                   <img
                     v-if="form.usdtQrPic"
                     class="money-img-el zoom-in-img"
                     :src="form.usdtQrPic"
                     alt=""
                     @click="previewHandle(form.usdtQrPic)"
                   >
                 </el-col>
               </el-row>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
    <el-dialog
      :append-to-body="true"
      :visible.sync="previewDialogShow"
      width="800px"
    >
      <img :src="previewImgUrl" width="100%">
    </el-dialog>
  </el-dialog>
</template>

<script>

import { mapGetters } from 'vuex'
import { add, edit } from '@/api/cus/tCustInfo'
export default {
  props: {
    isEdit: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    },
  },
  computed: {
    ...mapGetters([
      'ibTypeOptions',
      'sourceOptions',
      'ibRankOptions'
    ]),

    hierarchyDisplay(){
      if(this.isIb && this.form.ibRank == 1){
        return true;
      }else {
        return false;
      }
    }
  },
  data() {
    return {
      levelName: '',
      lockLevel: 1,
      custLevelList: [], //belongId
      loading: false,
      dialog: false,
      rules:{},
      activeName:'first',
      isIb:true,
      previewDialogShow: false,
      previewImgUrl: '',
      form: {
        linkId: '', // 客户新增
        id: '',
        sectorCode: '',
        belongId: '',
        custType: '',
        custSource: '',
        ibRank:'',
        custLvl: '',
        custLvlComment: '',
        custStatus: '',
        auditStatus: '',
        applyDate: '',
        mt4MainAcc: '',
        firstName: '',
        middlName: '',
        lastName: '',
        userName: '',
        password: '',
        gender: '',
        certType: '',
        certId: '',
        certValidatedImgFront: '',
        certValidatedImgBack: '',
        email: '',
        phone: '',
        birthday: '',
        telephone: '',
        openAccountFlag: 1,
        openLeverage: 1,
        website: '',
        rewardPointFlag: 1,
        nationality: '',
        homeCountryCode: '',
        homeProvince: '',
        homeCity: '',
        homeStreet: '',
        homeAddress: '',
        homePostcode: '',
        workCountryCode: '',
        workProvince: '',
        workCity: '',
        workStreet: '',
        workAddress: '',
        workPostcode: '',
        addressImg: '',
        bossFullname: '',
        title: '',
        industry: '',
        tradingAges: '',
        investExperienceYear: '',
        agentAges: '',
        annualIncome: '',
        fundsSource: '',
        havingClients: '',
        source: '',
        otherSource: '',
        bankOutletProvince: '',
        bankOutletCity: '',
        bankOutlet: '',
        bankName: '',
        bankAccount: '',
        swiftCode: '',
        bankFrontImg: '',
        bankBackImg: '',
        oldOrgImg: '',
        oldFundImg: '',
        oldDepositImg: '',
        oldClientImg: '',
        oldTradeImg: '',
        developArea: '',
        linkForChild: '',
        qrForChild: '',
        linkForCust: '',
        qrForCust: '',
        remark: '',
        usdtQrPic: '',
        usdtAdress: '',
        otctQrPic: '',
        otcAdress: '',
      }
    }
  },
  methods: {

    handleClick() {
    },
    cancel() {
      this.resetForm()
    },
    previewHandle(imgUrl){
      this.previewDialogShow = true;
      this.previewImgUrl = imgUrl;
    },
    doSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.isEdit) {
            this.doEdit()
          } else this.doAdd()
        }else{
          return false
        }
      })
    },
    doAdd() {
      let params = {
        ...this.form,
      };
      delete params.usdtQrPic;
      delete params.usdtAdress;

      delete params.otctQrPic;
      delete params.otcAdress;
      add().then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.$parent.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      let params = {
        ...this.form,
        modifyLevelFlag: this.lockLevel,
      };
      /*if(!this.isIb){
        delete params.linkId;
      }*/
      edit(params).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
      })
    },
    resetForm() {
      this.lockLevel = 1;
      this.activeName = 'first';
      this.imgUrls = [];
      this.isIb = true;
      this.dialog = false;
      this.$refs['form'].resetFields();
      this.form = {
        linkId:'',
        id: '',
        sectorCode: '',
        belongId: '',
        custType: '',
        custSource: '',
        ibRank:'',
        custLvl: '',
        custLvlComment: '',
        custStatus: '',
        auditStatus: '',
        applyDate: '',
        mt4MainAcc: '',
        firstName: '',
        middlName: '',
        lastName: '',
        userName: '',
        password: '',
        gender: '',
        certType: '',
        certId: '',
        certValidatedImgFront: '',
        certValidatedImgBack: '',
        email: '',
        phone: '',
        birthday: '',
        telephone: '',
        openLeverage: 1,
        website: '',
        nationality: '',
        homeCountryCode: '',
        homeProvince: '',
        homeCity: '',
        homeStreet: '',
        homeAddress: '',
        homePostcode: '',
        workCountryCode: '',
        workProvince: '',
        workCity: '',
        workStreet: '',
        workAddress: '',
        workPostcode: '',
        addressImg: '',
        bossFullname: '',
        title: '',
        industry: '',
        tradingAges: '',
        investExperienceYear: '',
        agentAges: '',
        annualIncome: '',
        fundsSource: '',
        havingClients: '',
        source: '',
        otherSource: '',
        bankOutletProvince: '',
        bankOutletCity: '',
        bankOutlet: '',
        bankName: '',
        bankAccount: '',
        swiftCode: '',
        bankFrontImg: '',
        bankBackImg: '',
        oldOrgImg: '',
        oldFundImg: '',
        oldDepositImg: '',
        oldClientImg: '',
        oldTradeImg: '',
        developArea: '',
        linkForChild: '',
        qrForChild: '',
        linkForCust: '',
        qrForCust: '',
        remark: '',
        usdtQrPic: '',
        usdtAdress: '',
        otctQrPic: '',
        otcAdress: '',
      }
    }
  }
}
</script>

<style lang="less" scoped>
.money-img-el {
  width: 50px;
  height: 50px;
}
  .zoom-in-img {
    cursor: zoom-in;
  }
  .ib-info-dialog {
    .level-lock-item {
      /deep/ .el-radio {
        margin-right: 10px;
      }
    }
  }
</style>
